
// Imports from respective modules
import  { DataProcessing, PDS, QA }  from "@mydex/pds-adaptor-frontend";
import  { InAppDatasets }  from "@mydex/inapp-datasets";
import  { Util, Validation }   from "@mydex/utils";
// import  { Dashboard }   from "@mydex/dashboards";
import  { SurveyGenerator }   from "@mydex/mydex-design-system";
import  { Dashboard }   from "@mydex/dashboards";
import  { 
  Content,
  Invites as MyDSInvites,
  Register,
}   from "@mydex/mydex-design-system";

// Loads code into the window context so can be references anywhere
window.DataProcessing = DataProcessing;
window.PDS = PDS;
window.QA = QA;
window.InAppDatasets = InAppDatasets;
window.Util = Util;
window.Validation = Validation;
window.Content = Content;
window.Content = SurveyGenerator;
window.MyDSInvites = MyDSInvites;
window.Register = Register;
window.Dashboard = Dashboard;

// Create click handlers for cards on a page. 
window.addEventListener("load", Content.init, false);
window.addEventListener("load", DataProcessing.load.pds, false);
window.addEventListener("load", SurveyGenerator.init, false);
